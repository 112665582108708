// App.tsx
import React, { useEffect, useContext, useState } from 'react';
import {
  BrowserRouter as Router, Routes, Route, Navigate,
} from 'react-router-dom';
import Interview from './pages/Interview';
import Permissions from './pages/Permissions';
import Instructions from './pages/Instructions';
import Login from './pages/Login';
import Signup from './pages/Signup';
import ForgotPassword from './pages/ForgotPassword';
import Profile from './pages/Profile';
import EmailVerificationPage from './components/EmailVerificationPage';

import { UserContext } from './UserContext';
import AdminDashboard from './pages/AdminDashboard';
import ApplicantDashboard from './pages/ApplicantDashboard';
import Landing from './pages/Landing';
import RequireAuth from './RequireAuth';

const App: React.FC = () => {
  const [audioStream, setAudioStream] = useState<MediaStream | null>(null);
  const [videoStream, setVideoStream] = useState<MediaStream | null>(null);
  const [screenStream, setScreenStream] = useState<MediaStream | null>(null);

  const { user } = useContext(UserContext);

  const setMediaStreams = (streams:
  {
    audioStream: MediaStream,
    videoStream: MediaStream,
    screenStream: MediaStream
  }) => {
    setAudioStream(streams.audioStream);
    setVideoStream(streams.videoStream);
    setScreenStream(streams.screenStream);
  };

  useEffect(() => {
    // Cleanup function when component unmounts or when streams change
    return () => {
      if (audioStream) {
        audioStream.getTracks().forEach((track) => { return track.stop(); });
      }
      if (videoStream) {
        videoStream.getTracks().forEach((track) => { return track.stop(); });
      }
      if (screenStream) {
        screenStream.getTracks().forEach((track) => { return track.stop(); });
      }
    };
  }, [audioStream, videoStream, screenStream]);
  
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Landing />} />
        <Route path="/login" element={<Login />} />
        <Route path="/verify-email" element={<EmailVerificationPage />} />
        <Route path="/signup" element={<Signup />} />
        <Route path="/forgot-password" element={<ForgotPassword />} />
        <Route path="/permissions/:interviewId" element={<Permissions setMediaStreams={setMediaStreams} />} />
        <Route path="/instructions/:interviewId" element={<Instructions />} />
        <Route
          path="/interview/:interviewId"
          element={(
            <Interview
              audioStream={audioStream}
              videoStream={videoStream}
              screenStream={screenStream}
            />
      )}
        />
        <Route
          path="/dashboard"
          element={(
            <RequireAuth>
              {user?.userType === 'recruiter' ? <AdminDashboard /> : <ApplicantDashboard />}
            </RequireAuth>
      )}
        />
        <Route
          path="/profile"
          element={(
            <RequireAuth>
              <Profile />
            </RequireAuth>
          )}
        />
        {/* Redirect to home page for any unknown routes */}
        <Route path="*" element={<Navigate to="/" />} />
      </Routes>
    </Router>
  );
};

export default App;
