// Signup.tsx
import React, { useContext, useEffect, useState } from 'react';
import {
  createUserWithEmailAndPassword,
  signInWithPopup,
  GoogleAuthProvider,
} from 'firebase/auth';
import { doc, getDoc, setDoc } from 'firebase/firestore';
import { useNavigate } from 'react-router-dom';
import { FaEye, FaEyeSlash } from 'react-icons/fa';
import { UserContext } from '../UserContext';
import { auth, db } from '../firebase';
import Button from '../components/Button';
import NavBar from '../components/NavBar';
import AlertModal from '../components/AlertModal';

interface Props {}

const Signup: React.FC<Props> = ({}) => {
  const navigate = useNavigate();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [userType, setUserType] = useState<'job_candidate' | 'recruiter'>('job_candidate'); // Default user type
  const [name, setName] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [accessCode, setAccessCode] = useState('');
  const [showAccessCodeModal, setShowAccessCodeModal] = useState(false);
  const [alertMessage, setAlertMessage] = useState('');
  const [alertTitle, setAlertTitle] = useState('');
  const [showAlert, setShowAlert] = useState(false);

  const { user, setUser } = useContext(UserContext);

  const showCustomAlert = (title: string, message: string) => {
    setAlertMessage(message);
    setAlertTitle(title);
    setShowAlert(true);
  };

  useEffect(() => {
    if (user) {
      navigate('/dashboard');
    }
  }, []);

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    if (password !== confirmPassword) {
      showCustomAlert('Error', 'Passwords do not match.');
      return;
    }
    if (userType === 'recruiter' && accessCode !== 'AXJDSDU') {
      showCustomAlert('Error', 'Invalid access code.');
      return;
    }
    try {
      const userCredential = await createUserWithEmailAndPassword(
        auth,
        email.toLowerCase().trim(),
        password,
      );
      const { user } = userCredential;
      // Store user type in Firestore
      await setDoc(doc(db, 'users', user.uid), {
        uid: user.uid,
        email,
        name,
        userType,
      });
      setUser(
        {
          uid: user.uid,
          email,
          name,
          userType,
        },
      )
      navigate('/dashboard');
    } catch (error: any) {
      console.error('Error signing up:', error);
      showCustomAlert('Error', error.message);
    }
  };

  const handleGoogleSignUp = async () => {
    if (!userType) {
      showCustomAlert('Error', 'Please select your user type before signing up.');
      return;
    }
    if (userType === 'recruiter' && accessCode !== 'AXJDSDU') {
      showCustomAlert('Error', 'Invalid access code!');
      return;
    }
    const provider = new GoogleAuthProvider();
    try {
      const userCredential = await signInWithPopup(auth, provider);
      const { user } = userCredential;

      // Store user type and name in Firestore
      const userDoc = doc(db, 'users', user.uid);
      const userSnapshot = await getDoc(userDoc);
      if (!userSnapshot.exists()) {
        await setDoc(userDoc, {
          uid: user.uid,
          email: user.email,
          name: user.displayName || '',
          userType,
        });
        setUser(
          {
            uid: user.uid,
            email: user.email,
            name: user.displayName || '',
            userType,
          },
        );
      }
      navigate('/dashboard');
    } catch (error: any) {
      console.error('Error signing up with Google:', error);
      showCustomAlert('Error', error.message);
    }
  };

  return (
    <div>
      <NavBar />
    <div className="flex items-center justify-center h-screen bg-blue-50 pt-16">
      <form
        className="bg-white p-10 rounded-md shadow-lg max-w-lg w-full border border-gray-200"
      >
        <h2 className="text-3xl font-bold mb-6 text-center text-gray-800">
          Create your account
        </h2>
        {/* Name Input */}
        <div className="mb-4">
          <label className="block text-gray-700 font-bold">Name</label>
          <input
        type="text"
        className="w-full mt-2 p-3 border rounded-md focus:outline-none focus:ring-2 focus:ring-blue-300"
        placeholder="Enter your full name"
        value={name}
        onChange={(e) => setName(e.target.value)}
        required
          />
        </div>
        {/* Email Input */}
        <div className="mb-4">
          <label className="block text-gray-700 font-bold">Email</label>
          <input
        type="email"
        className="w-full mt-2 p-3 border rounded-md focus:outline-none focus:ring-2 focus:ring-blue-300"
        placeholder="Enter your email"
        value={email}
        onChange={(e) => setEmail(e.target.value)}
        required
          />
        </div>
        {/* Password Input */}
        <label className="block text-gray-700 font-bold">Password</label>

        <div className="mb-4 relative">
          <input
        type={showPassword ? 'text' : 'password'}
        className="w-full mt-2 p-3 border rounded-md focus:outline-none focus:ring-2 focus:ring-blue-300"
        placeholder="Enter your password"
        value={password}
        onChange={(e) => setPassword(e.target.value)}
        required
          />
          <div
        className="absolute inset-y-0 right-0 pr-3 flex items-center cursor-pointer"
        onClick={() => setShowPassword(!showPassword)}
          >
        {showPassword ? <FaEyeSlash /> : <FaEye />}
          </div>
        </div>
        {/* Confirm Password Input */}
        <label className="block text-gray-700 font-bold">
          Confirm Password
        </label>
        <div className="mb-6 relative">
          <input
        type={showConfirmPassword ? 'text' : 'password'}
        className="w-full mt-2 p-3 border rounded-md focus:outline-none focus:ring-2 focus:ring-blue-300"
        placeholder="Confirm your password"
        value={confirmPassword}
        onChange={(e) => setConfirmPassword(e.target.value)}
        required
          />
          <div
        className="absolute inset-y-0 right-0 pr-3 flex items-center cursor-pointer"
        onClick={() => setShowConfirmPassword(!showConfirmPassword)}
          >
        {showConfirmPassword ? <FaEyeSlash /> : <FaEye />}
          </div>
        </div>
        {/* User Type Selection */}
        <div className="mb-6">
          <label className="block text-gray-700 font-bold mb-2">I am a:</label>
          <div className="flex items-center">
        <input
          type="radio"
          id="job_candidate"
          name="userType"
          value="job_candidate"
          checked={userType === 'job_candidate'}
          onChange={(e) => {
            setUserType('job_candidate');
            setAccessCode('');
          }}
          className="mr-2"
        />
        <label htmlFor="job_candidate" className="mr-4">
          Job Candidate
        </label>
        <input
          type="radio"
          id="recruiter"
          name="userType"
          value="recruiter"
          checked={userType === 'recruiter'}
          onChange={(e) => {
            setUserType('recruiter');
            setShowAccessCodeModal(true);
          }}
          className="mr-2"
        />
        <label htmlFor="recruiter">Recruiter</label>
          </div>
        </div>
        {/* Sign Up Button */}
        <Button
          onClick={handleSubmit}
          className="w-full mb-3"
          bold
        >
          Sign Up
        </Button>
        {/* Google Sign Up Button */}
        <Button
          onClick={handleGoogleSignUp}
          className="w-full"
          bold
          inverted
        >
          Sign Up with Google
        </Button>
        {/* Navigate to Login */}
        <p className="text-sm text-gray-600 mt-4 text-center font-bold">
          Already have an account?{' '}
          <a
            href="#"
            className="underline font-bold text-[#20B486]"
             onClick={(e) => {
              e.preventDefault();
              navigate('/login');
            }}
          >
            Log in
          </a>{' '}
          instead.
        </p>
      </form>

      {/* Access Code Modal */}
      {showAccessCodeModal && (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50">
          <div className="bg-white p-6 rounded-md shadow-lg">
            <h2 className="text-xl font-bold mb-4">Enter Access Code</h2>
            <input
              type="text"
              className="w-full p-3 border rounded-md focus:outline-none focus:ring-2 focus:ring-blue-300"
              placeholder="Access Code"
              value={accessCode}
              onChange={(e) => setAccessCode(e.target.value)}
            />
            <div className="flex justify-end mt-4">
              <Button
                onClick={() => {
                  setShowAccessCodeModal(false);

                  if (accessCode === 'AXJDSDU') {
                    setUserType('recruiter');
                  } else{
                    showCustomAlert('Error', 'Invalid access code. Contact us if you would like to become a recruiter.');
                    setUserType('job_candidate');
                  }
                }}
              >
                Submit
              </Button>
            </div>
          </div>
        </div>
      )}

      {/* Alert Modal */}
      <AlertModal
        title={alertTitle}
        isOpen={showAlert}
        message={alertMessage}
        onClose={() => setShowAlert(false)}
      />
    </div>
    </div>
  );
};

export default Signup;
