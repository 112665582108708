// Permissions.tsx

import React, { useState, useEffect, useRef } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import Button from '../components/Button';

interface Props {
  setMediaStreams: (streams: {
    audioStream: MediaStream;
    videoStream: MediaStream;
    screenStream: MediaStream;
  }) => void;
}

const Permissions: React.FC<Props> = ({ setMediaStreams }) => {
  const [step, setStep] = useState(0);

  const { interviewId = '' } = useParams<{ interviewId: string }>();
  const navigate = useNavigate();

  // State variables for individual streams
  const [audioStream, setAudioStream] = useState<MediaStream | null>(null);
  const [videoStream, setVideoStream] = useState<MediaStream | null>(null);
  const [screenStream, setScreenStream] = useState<MediaStream | null>(null);

  const [mediaStream, setMediaStream] = useState<MediaStream | null>(null);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [audioLevel, setAudioLevel] = useState<number>(0);
  const audioRef = useRef<null | AnalyserNode>(null);

  const [showScreenVideo, setShowScreenVideo] = useState<boolean>(false); // controls whether screen video is displayed

  const permissions = ['Microphone', 'Webcam', 'Screen Sharing'];

  const requestPermission = async () => {
    try {
      setErrorMessage(null);
      let stream: MediaStream | null = null;

      if (permissions[step] === 'Microphone') {
        // Get Microphone
        stream = await navigator.mediaDevices.getUserMedia({ audio: true });
        setAudioStream(stream);

        // Audio level visualization
        const audioContext = new (window.AudioContext || (window as any).webkitAudioContext)();
        const analyser = audioContext.createAnalyser();
        const source = audioContext.createMediaStreamSource(stream);
        source.connect(analyser);
        analyser.fftSize = 64;

        const dataArray = new Uint8Array(analyser.frequencyBinCount);
        audioRef.current = analyser;

        const updateAudioLevel = () => {
          analyser.getByteFrequencyData(dataArray);
          const avg = dataArray.reduce((a, b) => a + b) / dataArray.length;
          setAudioLevel(avg);
          requestAnimationFrame(updateAudioLevel);
        };
        updateAudioLevel();

      } else if (permissions[step] === 'Webcam') {
        // Get Webcam
        stream = await navigator.mediaDevices.getUserMedia({ video: true });
        setVideoStream(stream);

      } else if (permissions[step] === 'Screen Sharing') {
        // Get Screen
        stream = await (navigator.mediaDevices as any).getDisplayMedia({ video: true });

        // Check if user is sharing the entire screen
        // (Note: This relies on modern browsers supporting `displaySurface`)
        const track = stream.getVideoTracks()[0];
        const settings = track.getSettings();

        // If it's not "monitor", user likely selected a tab or window
        if (!('displaySurface' in settings)) {
          console.warn('displaySurface is not defined in track settings');
        } else if (settings.displaySurface !== 'monitor') {
          setErrorMessage(
            'Please share your entire screen, not a window or browser tab. Close the dialog and try again.'
          );
          track.stop();
          return;
        }

        setScreenStream(stream);
      }

      if (stream) {
        setMediaStream(stream);
      }
    } catch (error) {
      console.error(error);
      setErrorMessage(
        `Failed to get ${permissions[step]} access. Please ensure you have granted the necessary permissions.`
      );
    }
  };

  const handleContinue = () => {
    if (step < permissions.length - 1) {
      setStep(step + 1);
      setMediaStream(null);
      setErrorMessage(null);
      setShowScreenVideo(false);
    } else {
      // Check if streams are available
      if (audioStream && videoStream && screenStream) {
        // Pass the collected streams to App.tsx
        setMediaStreams({ audioStream, videoStream, screenStream });
        navigate(`/instructions/${interviewId}`);
      } else {
        setErrorMessage('Please ensure all permissions are granted.');
      }
    }
  };

  useEffect(() => {
    return () => {
      // Only stop the mediaStream if it is not one of the main streams
      if (
        mediaStream &&
        mediaStream !== audioStream &&
        mediaStream !== videoStream &&
        mediaStream !== screenStream
      ) {
        mediaStream.getTracks().forEach((track) => track.stop());
      }
      if (audioRef.current) {
        audioRef.current = null;
      }
    };
  }, [step, mediaStream, audioStream, videoStream, screenStream]);

  return (
    <div className="flex items-center justify-center h-screen bg-blue-50">
      <div className="bg-white shadow-lg p-6 rounded-lg flex w-3/4">
        {/* Checklist Sidebar */}
        <div className="w-1/4 bg-gray-50 p-4 rounded-l-lg border-r-2 border-gray-200">
          <h2 className="text-xl font-semibold mb-4">Setup Checklist</h2>
          <ul>
            {permissions.map((permission, index) => (
              <li
                key={permission}
                className={`flex items-center mb-2 ${
                  index <= step ? 'text-[#20B486]' : 'text-gray-500'
                }`}
              >
                <span
                  className={`mr-2 h-4 w-4 rounded-full border-2 ${
                    index <= step ? 'bg-[#20B486] border-[#20B486]' : 'border-gray-300'
                  }`}
                ></span>
                {permission}
              </li>
            ))}
          </ul>
        </div>

        {/* Main Content */}
        <div className="flex-grow p-8">
          <h2 className="text-2xl font-bold mb-6">Enable {permissions[step]} Access</h2>

          {/* Extra instructions specifically for screen sharing */}
          {permissions[step] === 'Screen Sharing' && !mediaStream && (
            <p className="bg-yellow-50 p-4 border-l-4 border-yellow-400 text-yellow-800 mb-4 rounded">
              <strong>Important:</strong> Please select your <em>entire screen</em> (not just a browser tab or window).
            </p>
          )}

          {!mediaStream && (
            <Button onClick={requestPermission} bold>
              Enable {permissions[step]}
            </Button>
          )}

          {errorMessage && (
            <p className="text-red-700 bg-red-100 py-2 px-4 rounded mb-4 mt-4">{errorMessage}</p>
          )}

          {/* Microphone Preview */}
          {mediaStream && permissions[step] === 'Microphone' && (
            <div className="mt-4">
              <audio
                autoPlay
                ref={(audio) => {
                  if (audio) audio.srcObject = mediaStream;
                }}
                className="hidden"
              />
              {/* Updated "Waveform" UI (no longer looks like a loading bar) */}
              <div className="relative w-full h-4 bg-gray-300 rounded-full overflow-hidden">
                {/* Blurred "glow" bar */}
                <div
                  className="absolute inset-0 h-full bg-[#20B486] transition-all opacity-40 blur-sm"
                  style={{ width: `${audioLevel}%` }}
                ></div>
                {/* Solid bar */}
                <div
                  className="absolute inset-0 h-full bg-[#20B486] transition-all"
                  style={{ width: `${audioLevel}%` }}
                ></div>
              </div>
              <p className="mt-2 text-gray-700">
                Speak into your microphone to see the volume level.
              </p>
            </div>
          )}


          {/* Webcam Preview */}
          {mediaStream && permissions[step] === 'Webcam' && (
            <video
              autoPlay
              playsInline
              ref={(video) => {
                if (video && video.srcObject !== mediaStream) {
                  video.srcObject = mediaStream;
                }
              }}
              className="w-full rounded-md shadow-md mt-4"
              style={{ transform: 'scaleX(-1)' }}
            />
          )}

          {/* Screen Sharing Preview */}
          {mediaStream && permissions[step] === 'Screen Sharing' && (
            <div className="mt-4">
              {!showScreenVideo ? (
                <div className="p-4 border-2 border-dashed border-gray-300 rounded-md">
                  <p className="mb-2 text-gray-700">
                    <span className="text-green-600 text-xl mr-2">✔</span>
                    Your screen is being shared successfully.
                  </p>
                  <Button bold onClick={() => setShowScreenVideo(true)}>
                    View Screen
                  </Button>
                </div>
              ) : (
                <video
                  autoPlay
                  playsInline
                  ref={(video) => {
                    if (video && video.srcObject !== mediaStream) {
                      video.srcObject = mediaStream;
                    }
                  }}
                  className="w-full rounded-md shadow-md mt-4"
                />
              )}
            </div>
          )}

          {mediaStream && (
            <Button onClick={handleContinue} bold className="mt-4">
              Continue
            </Button>
          )}
        </div>
      </div>
    </div>
  );
};

export default Permissions;
