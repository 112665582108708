import React from 'react';

const Button = ({
  children,
  onClick,
  className = '',
  inverted = false,
  link = false,
  color = 'green',
  bold,
  disabled
}: {
  children: React.ReactNode;
  onClick: (e: any) => void;
  className?: string;
  inverted?: boolean;
  link?: boolean;
  color?: 'gray' | 'red' | 'blue' | 'green';
  bold?: boolean;
  disabled?: boolean;
}) => {
  const baseClasses = `rounded-md transition-colors duration-300 ease-in-out ${bold ? 'font-bold' : ''}`;

  let buttonClasses = '';

  if (link) {
    buttonClasses = 'underline text-blue-600 hover:text-blue-800';
  } else if (inverted) {
    const invertedColorClasses: { [key: string]: string } = {
      gray: `text-gray-500 border border-gray-500 ${disabled ? '' : 'hover:bg-gray-500 hover:text-white'}`,
      red: `text-red-500 border border-red-500 ${disabled ? '' : 'hover:bg-red-500 hover:text-white'}`,
      blue: `text-blue-500 border border-blue-500 ${disabled ? '' : 'hover:bg-blue-500 hover:text-white'}`,
      green: `text-[#20B486] border border-[#20B486] ${disabled ? '' : 'hover:bg-[#20B486] hover:text-white'}`,
    };
    buttonClasses = invertedColorClasses[color];
    } else {
    const colorClasses: { [key: string]: string } = {
      gray: `bg-gray-500 text-white ${disabled ? '' : 'hover:bg-gray-700'}`,
      red: `bg-red-500 text-white ${disabled ? '' : 'hover:bg-red-700'}`,
      blue: `bg-blue-500 text-white ${disabled ? '' : 'hover:bg-blue-700'}`,
      green: `bg-[#20B486] text-white ${disabled ? '' : 'hover:bg-[#1a906b]'}`,
    };
    buttonClasses = colorClasses[color];
  }

  return (
    <button
      type='button'
      disabled={disabled}
      onClick={onClick}
      className={`${baseClasses} ${link ? '' : 'px-6 py-2'} ${buttonClasses} ${className} ${
        disabled ? 'opacity-50 cursor-not-allowed' : ''
      }`}
    >
      {children}
    </button>
  );
};

export default Button;
