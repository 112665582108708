import React, { useContext, useState, useRef, useEffect } from 'react';
import { auth } from '../firebase';
import { UserContext } from '../UserContext';
import { useNavigate } from 'react-router-dom';
import FullLogo from '../assets/full_logo.png';
import Button from './Button';
import { UserIcon } from '@heroicons/react/outline';

interface Props {
  loginPage?: boolean;
}

const NavBar: React.FC<Props> = ({ loginPage }) => {
  const navigate = useNavigate();
  const { user } = useContext(UserContext);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const dropdownRef = useRef<HTMLDivElement>(null);

  const handleLogout = async () => {
    try {
      await auth.signOut();
      navigate('/login');
    } catch (error) {
      console.error('Error logging out:', error);
    }
  };

  // Close dropdown when clicking outside
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target as Node)) {
        setDropdownOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, []);

  return (
    <nav className="bg-white shadow-sm fixed top-0 left-0 right-0 z-10">
      <div className="flex items-center justify-between px-6 py-3">
        <div className="flex-shrink-0">
          <img
            src={FullLogo}
            alt="Finterview Logo"
            className="h-8 cursor-pointer"
            onClick={() => navigate('/')}
          />
        </div>

        <div>
          {user ? (
            <div className="relative" ref={dropdownRef}>
              <button
                onClick={() => setDropdownOpen(!dropdownOpen)}
                className="flex items-center space-x-2 text-gray-700 hover:text-gray-900"
              >
                <UserIcon className="h-6 w-6" />
                <span>{user.name}</span>
              </button>

              {dropdownOpen && (
                <div className="absolute right-0 mt-2 w-48 bg-white rounded-md shadow-lg py-1 z-50">
                   <button
                      onClick={() => {
                        navigate('/dashboard');
                        setDropdownOpen(false);
                      }}
                      className="block w-full text-left px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
                    >
                      Dashboard
                    </button>
                  <button
                    onClick={() => {
                      navigate('/profile');
                      setDropdownOpen(false);
                    }}
                    className="block w-full text-left px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
                  >
                    Profile Settings
                  </button>
                  
                  <button
                    onClick={handleLogout}
                    className="block w-full text-left px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
                  >
                    Logout
                  </button>
                </div>
              )}
            </div>
          ) : (
            <>
              {!loginPage && (
                <>
              <Button 
                className="mr-4"
                onClick={() => navigate('/signup')}
              >
                Sign Up
              </Button>
              <Button
                onClick={() => navigate('/login')}
                inverted
              >
                Login
              </Button>
              </>
              ) }
            </>

          )}
        </div>
      </div>
    </nav>
  );
};

export default NavBar;
