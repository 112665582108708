import React, { useContext, useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { sendEmailVerification } from 'firebase/auth';
import { UserContext } from '../UserContext';
import { auth } from '../firebase';
import Button from '../components/Button';
import NavBar from '../components/NavBar';
import AlertModal from '../components/AlertModal';

const EmailVerificationPage: React.FC = () => {
  const navigate = useNavigate();
  const { user } = useContext(UserContext);
  const [resendDisabled, setResendDisabled] = useState(false);
  const [resendCountdown, setResendCountdown] = useState(0);
  const [isVerified, setIsVerified] = useState(false);
  const [alertMessage, setAlertMessage] = useState('');
  const [alertTitle, setAlertTitle] = useState('');
  const [showAlert, setShowAlert] = useState(false);

  const showCustomAlert = (title: string, message: string) => {
    setAlertMessage(message);
    setAlertTitle(title);
    setShowAlert(true);
  };

  // Check verification status periodically
  useEffect(() => {
    if (!auth.currentUser) return;

    const checkVerification = async () => {
      await auth.currentUser.reload();
      if (auth.currentUser.emailVerified) {
        setIsVerified(true);
        navigate('/dashboard');
      }
    };

    // Check immediately
    checkVerification();

    // Then check every 5 seconds
    const interval = setInterval(checkVerification, 5000);

    return () => clearInterval(interval);
  }, [navigate]);

  const handleResendVerification = async () => {
    if (resendDisabled) return;

    const currentUser = auth.currentUser;
    if (!currentUser) {
      showCustomAlert('Error', 'No user found. Please try logging in again.');
      navigate('/login');
      return;
    }

    try {
      await sendEmailVerification(currentUser);
      setResendDisabled(true);
      setResendCountdown(60);
      
      const timer = setInterval(() => {
        setResendCountdown((prev) => {
          if (prev <= 1) {
            clearInterval(timer);
            setResendDisabled(false);
            return 0;
          }
          return prev - 1;
        });
      }, 1000);
      
      showCustomAlert('Success', 'Verification email sent successfully!');
    } catch (error: any) {
      console.error('Error sending verification email:', error);
      showCustomAlert('Error', 'Error sending verification email. Please try again later.');
    }
  };

  const handleCheckVerification = async () => {
    const currentUser = auth.currentUser;
    if (!currentUser) {
      showCustomAlert('Error', 'No user found. Please try logging in again.');
      navigate('/login');
      return;
    }

    try {
      await currentUser.reload();
      if (currentUser.emailVerified) {
        navigate('/dashboard');
      } else {
        showCustomAlert('Error', 'Email not verified yet. Please check your inbox and verify your email.');
      }
    } catch (error: any) {
      console.error('Error checking verification status:', error);
      showCustomAlert('Error', 'Error checking verification status. Please try again later.');
    }
  };

  // If already verified, redirect
  if (isVerified) {
    return null;
  }

  return (
    <div>
      <NavBar />
      <div className="flex items-center justify-center min-h-screen bg-blue-50">
        <div className="bg-white p-10 rounded-md shadow-lg max-w-lg w-full text-center">
          <h2 className="text-2xl font-bold mb-4">Email Verification Required</h2>
          <p className="mb-6">
            Before you can access the recruiter dashboard, we need to verify your email address. 
            We will send a verification link to {auth.currentUser?.email || user?.email}.
          </p>
          <p className="text-sm text-gray-600 mb-8">
            Click below to send the verification email.
          </p>

          <Button
            onClick={handleResendVerification}
            className="w-full mb-4"
            disabled={resendDisabled}
            bold
          >
            {resendDisabled 
              ? `Resend available in ${resendCountdown}s` 
              : 'Send Verification Email'}
          </Button>

          <Button
            onClick={handleCheckVerification}
            className="w-full"
            inverted
            bold
          >
            I've Verified My Email
          </Button>

          {/* Add Alert Modal */}
          <AlertModal
            isOpen={showAlert}
            message={alertMessage}
            title={alertTitle}
            onClose={() => setShowAlert(false)}
          />

        </div>
      </div>
    </div>
  );
};

export default EmailVerificationPage;
