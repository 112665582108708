import React from 'react';
import Button from './Button';

interface ConfirmModalProps {
  isOpen: boolean;
  title?: string;
  message: string;
  onConfirm: () => void;
  onClose: () => void;
}

const ConfirmModal: React.FC<ConfirmModalProps> = ({ isOpen, title, message, onConfirm, onClose }) => {
  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
      <div className="bg-white p-6 rounded-md shadow-lg max-w-md w-full mx-4">
        <h2 className="text-xl font-bold mb-4">{title || 'Confirm'}</h2>
        <p className="mb-6 text-gray-700">{message}</p>
        <div className="flex justify-end space-x-2">
          <Button onClick={onClose} bold inverted>Cancel</Button>
          <Button onClick={onConfirm} bold>Yes</Button>
        </div>
      </div>
    </div>
  );
};

export default ConfirmModal;
