import Question from "../../types/Question";

const SpreadsheetSkills: Question[] = [
    {
        questionNumber: 1,
        questionType: 'Spreadsheet',
        spreadsheetLink: "https://docs.google.com/spreadsheets/d/1DA9_RR3lzPwo5ST0H4DU3BtJQu-xkwCWD8OPb4_tbuA/edit?usp=sharing",
        questionInstruction: "",
        questionAssumptions: "",
        questionParts: [
            {
                id: 1,
                text: "Pivot Table summarizing units sold per year in each category of clothing.",
                answerFormat: 'number',
                inputType: 'spreadsheet',
                cell: 'B21:G28',
                cellType: 'block',
            },
            {
                id: 2,
                text: "Use INDEX-MATCH on the Sales Data table to find the Total Revenue for 'Jackets' in 2023. Insert formula into B33.",
                answerFormat: 'number',
                inputType: 'spreadsheet',
                cell: 'B31',
                cellType: 'single',
            },
            {
                id: 3,
                text: "Use VLOOKUP on the Sales Data table to find the Total Revenue for Jackets in the first year they were dropped.",
                answerFormat: 'number',
                inputType: 'spreadsheet',
                cell: 'B34',
                cellType: 'single',
            },
        ],
        requiresPreviousQuestionCompleted: true,
        questionTitle: "Spreadsheet Skills Part 1"
    },
    {
        questionNumber: 2,
        questionType: 'Spreadsheet',
        spreadsheetLink: "https://docs.google.com/spreadsheets/d/1ajCYjdkMk9TGdLoVd_QmgKSqD8o5QntE_hgoIr2YfrM/edit?usp=sharing",
        questionInstruction: "",
        questionAssumptions: "",
        questionParts: [
            {
                id: 1,
                text: "Please use the IF function to construct a algorithm that creates a message to purchase a stock if it's prior period growth was more than 2%, sell if less than -2%, and holds otherwise. ",
                answerFormat: 'number',
                inputType: 'spreadsheet',
                cell: 'D9:D21',
                cellType: 'column',
            },
            {
                id: 2,
                text: "You have been asked to create a visual for the funds performance over time. Use conditional formatting to hire negative rates in red, and positive in green.",
                answerFormat: 'string',
                inputType: 'spreadsheet',
                cell: 'C26:C40',
                cellType: 'column',
            },
        ],
        requiresPreviousQuestionCompleted: false,
        questionTitle: "Spreadsheet Skills Part 2"
    },
    {
        questionNumber: 3,
        questionType: 'Spreadsheet',
        spreadsheetLink: "https://docs.google.com/spreadsheets/d/1dWPmrdsL0q_jZra8G97WG78LkElzuzK9T0a1g7k8qpQ/edit?usp=sharing",
        questionInstruction: "",
        questionAssumptions: "",
        questionParts: [
            {
                id: 1,
                text: "Calculate the annual payment of a bond with a face value of 100 that matures over 10 periods at a 4.5% interest rate? ",
                answerFormat: 'number',
                inputType: 'spreadsheet',
                cell: 'C14',
                cellType: 'single',
            },
            {
                id: 2,
                text: "Calculate the Net Present Value of a Factory which cost 2mm to set up, and will return 1mm in year 1, 2mm in year 2, 2.5 mm in year 3, and 2mm in year 4.",
                answerFormat: 'number',
                inputType: 'spreadsheet',
                cell: 'C20',
                cellType: 'single',
            },
            {
                id: 3,
                text: "Calculate the Beta of the following stock with the market.",
                answerFormat: 'number',
                inputType: 'spreadsheet',
                cell: 'C35',
                cellType: 'single',
            },
        ],
        requiresPreviousQuestionCompleted: false,
        questionTitle: "Spreadsheet Skills Part 3"
    },
];

export default SpreadsheetSkills;
