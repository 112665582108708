// Login.tsx
import React, { useContext, useEffect, useState } from 'react';
import {
  signInWithEmailAndPassword,
  signInWithPopup,
  GoogleAuthProvider,
} from 'firebase/auth';
import { useNavigate } from 'react-router-dom';
import { FaEye, FaEyeSlash } from 'react-icons/fa';
import { UserContext } from '../UserContext';
import { auth, db } from '../firebase';
import Button from '../components/Button';
import NavBar from '../components/NavBar';
import AlertModal from '../components/AlertModal';
import { doc, getDoc, setDoc } from 'firebase/firestore';

interface Props {}

const Login: React.FC<Props> = () => {
  const navigate = useNavigate();
  const { user, setUser } = useContext(UserContext);

  useEffect(() => {
    if (user) {
      navigate('/dashboard');
    }
  }, [user]);

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [alertMessage, setAlertMessage] = useState('');
  const [alertTitle, setAlertTitle] = useState('');
  const [showAlert, setShowAlert] = useState(false);

  const showCustomAlert = (title: string, message: string) => {
    setAlertMessage(message);
    setAlertTitle(title);
    setShowAlert(true);
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    try {
      await signInWithEmailAndPassword(auth, email, password);
      navigate('/dashboard');
    } catch (error: any) {
      console.error('Error signing in:', error);
      setErrorMessage('Incorrect email or password.');
    }
  };

  const handleGoogleSignIn = async () => {
    const provider = new GoogleAuthProvider();
    try {
      const userCredential = await signInWithPopup(auth, provider);
      const { user } = userCredential;
      // Store user type and name in Firestore
      const userDoc = doc(db, 'users', user.uid);
      const userSnapshot = await getDoc(userDoc);
      // if account doesn't exist create an account for them. Default userType is job_candidate
      if (!userSnapshot.exists()) {
        await setDoc(userDoc, {
          uid: user.uid,
          email: user.email,
          name: user.displayName || '',
          userType: 'job_candidate',
        });
        setUser({
          uid: user.uid,
          email: user.email,
          name: user.displayName || '',
          userType: 'job_candidate',
        });
      }
      navigate('/dashboard');
    } catch (error: any) {
      console.error('Error signing in with Google:', error);
      showCustomAlert('Error', error.message);
    }
  };

  return (
    <div>
      <NavBar loginPage/>
      <div className="flex items-center justify-center h-screen bg-blue-50">
        <form className="bg-white p-10 rounded-md shadow-lg max-w-sm w-full border border-gray-200">
          <h2 className="text-3xl font-bold mb-6 text-center text-gray-800">
            Sign in to continue
          </h2>
          
          {/* Email Input */}
          <div className="mb-4">
            <label className="block text-gray-700 font-bold">Email</label>
            <input
              type="email"
              className="w-full mt-2 p-3 border rounded-md focus:outline-none focus:ring-2 focus:ring-blue-300"
              placeholder="Enter your email"
              value={email}
              onChange={(e) => {
                setEmail(e.target.value);
                setErrorMessage('');
              }}
              required
            />
          </div>
          {/* Password Input */}
          <label className="block text-gray-700 font-bold">Password</label>
          <div className="mb-4 relative">
            <input
              type={showPassword ? 'text' : 'password'}
              className="w-full mt-2 p-3 border rounded-md focus:outline-none focus:ring-2 focus:ring-blue-300"
              placeholder="Enter your password"
              value={password}
              onChange={(e) => {
                setPassword(e.target.value);
                setErrorMessage('');
              }}
              required
            />
            <div
              className="absolute inset-y-0 right-0 pr-3 flex items-center cursor-pointer"
              onClick={() => setShowPassword(!showPassword)}
            >
              {showPassword ? <FaEyeSlash /> : <FaEye />}
            </div>
          </div>

           {/* Error Message */}
           {errorMessage && (
            <div className="text-red-600 font-bold mb-4 text-start">
              {errorMessage}
            </div>
          )}

          
          {/* Forgot Password */}
          <div className="text-right mb-6">
            <a
              href="#"
              className="text-sm font-bold text-[#20B486] hover:underline"
              onClick={() => navigate('/forgot-password')}
            >
              Forgot password?
            </a>
          </div>

         

          {/* Sign In Button */}
          <Button onClick={handleSubmit} className="w-full font-bold mb-3">
            Sign in
          </Button>
          {/* Google Sign In */}
          <Button onClick={handleGoogleSignIn} className="w-full font-bold" inverted>
            Sign in with Google
          </Button>
          {/* Navigate to Signup */}
          <p className="text-sm text-gray-600 mt-4 text-center font-bold">
            Don't have an account?{' '}
            <a
              href="#"
              className="underline font-bold text-[#20B486]"
              onClick={(e) => {
                e.preventDefault();
                navigate('/signup');
              }}
            >
              Sign up
            </a>{' '}
            instead.
          </p>

          {/* Add Alert Modal */}
          <AlertModal
            isOpen={showAlert}
            message={alertMessage}
            title={alertTitle}
            onClose={() => setShowAlert(false)}
          />
        </form>
      </div>
    </div>
  );
};

export default Login;

