import React, { useState, useEffect, useRef } from 'react';
import Button from './Button';
import QuestionPart from '../../types/QuestionPart';
import {
  CheckCircleIcon,
  ExclamationCircleIcon,
  DownloadIcon,
  ChevronDownIcon,
  ChevronRightIcon,
} from '@heroicons/react/solid';
import QuestionAnswer from '../../types/QuestionAnswer';
import ConfirmModal from './ConfirmModal';

interface SpreadsheetQuestionProps {
  questionParts: QuestionPart[];
  sheetLink: string;
  onBack: () => void;
  onSaveAnswers: (answers: QuestionAnswer) => void;
  initialAnswers: QuestionAnswer;
  questionInstruction: string;
  questionAssumptions: string;
  disableAfterCompleting: boolean;
  timesUp: boolean;
}

const SpreadsheetQuestion: React.FC<SpreadsheetQuestionProps> = ({
  questionParts,
  sheetLink,
  onBack,
  onSaveAnswers,
  initialAnswers,
  questionInstruction,
  questionAssumptions,
  disableAfterCompleting,
  timesUp,
}) => {
  const [answers, setAnswers] = useState<QuestionAnswer>(initialAnswers);

  const [isSaved, setIsSaved] = useState<boolean>(true); // Tracks save status
  const [lastSaveTime, setLastSaveTime] = useState<Date | null>(null); // Stores last save timestamp
  const [isExtracting, setIsExtracting] = useState<boolean>(false); // Tracks extraction status
  const [showFormulas, setShowFormulas] = useState<boolean>(false); // Toggle for showing formulas
  const lastSavedAnswersRef = useRef<QuestionAnswer>({});

  const [isLeftPanelOpen, setIsLeftPanelOpen] = useState<boolean>(true); // Toggle for left panel
  const [isSaving, setIsSaving] = useState<boolean>(false); // Tracks saving status
  const [confirmMessage, setConfirmMessage] = useState('');
  const [showConfirm, setShowConfirm] = useState(false);
  const saveTimeoutRef = useRef<NodeJS.Timeout | null>(null); // Reference to the debounce timeout

  // Separate question parts into visible vs hidden
  const visibleParts = questionParts.filter((part) => !part.hideQuestionPart);

  // Check if there's at least one spreadsheet input among **all** parts
  const hasSpreadsheetInput = questionParts.some((part) => part.inputType === 'spreadsheet');
  // Check if there's at least one spreadsheet input among **visible** parts
  const hasVisibleSpreadsheet = visibleParts.some((part) => part.inputType === 'spreadsheet');

  // Extract Spreadsheet ID from the link
  const getSpreadsheetIdFromLink = (link: string): string | null => {
    const regex = /\/d\/([a-zA-Z0-9-_]+)/;
    const match = link.match(regex);
    return match ? match[1] : null;
  };

  const showCustomConfirm = (message: string) => {
    setConfirmMessage(message);
    setShowConfirm(true);
  }

  // Fetch values for spreadsheet-based questions on initial load
  useEffect(() => {
    const spreadsheetCells = questionParts
      .filter((part) => part.inputType === 'spreadsheet' && part.cell)
      .map((part) => ({
        id: part.id,
        cell: part.cell,
        cellType: part.cellType,
      }));

    if (spreadsheetCells.length > 0) {
      fetchCellValues(spreadsheetCells).then((cellValues) => {
        const updatedAnswers = { ...answers };
        questionParts.forEach((part) => {
          if (part.inputType === 'spreadsheet' && part.cell) {
            updatedAnswers[part.id] = cellValues[part.id] || { value: '', formula: '', multipleValues: false };
          }
        });
        setAnswers(updatedAnswers);
      });
    }
  }, [questionParts, sheetLink]);

  // Debounce Auto-save: Save after 5 seconds of no typing
  useEffect(() => {
    if (!isSaved) {
      // Clear any existing timeout to reset the debounce
      if (saveTimeoutRef.current) {
        clearTimeout(saveTimeoutRef.current);
      }

      // Set a new timeout to save after 5 seconds
      saveTimeoutRef.current = setTimeout(() => {
        handleSave();
      }, 5000);
    }

    // Cleanup function to clear timeout if component unmounts or answers change
    return () => {
      if (saveTimeoutRef.current) {
        clearTimeout(saveTimeoutRef.current);
      }
    };
  }, [answers, isSaved]);

  useEffect(() => {
    if (timesUp) {
      handleBack();
    }
  }, [timesUp]);

  const fetchCellValues = async (
    cells: { cell: string; cellType: string; id: number }[]
  ): Promise<QuestionAnswer> => {
    const spreadsheetId = getSpreadsheetIdFromLink(sheetLink);

    if (!spreadsheetId) {
      console.error('Invalid sheet link');
      return {};
    }

    try {
      const response = await fetch('/api/fetch-cell-value', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ spreadsheetId, cells }),
      });

      const data = await response.json();

      if (data.success) {
        const cellValues: { [cellId: string]: { value: any; formula: any, multipleValues: boolean } } = {};
        data.cellValues.forEach((item: { cell: string; value: any; formula: any; id: number, multipleValues: boolean }) => {
          cellValues[item.id] = {
            value: item.value,
            formula: item.formula,
            multipleValues: item.multipleValues,
          };
        });
        console.log('Fetched cell values:', cellValues);
        return cellValues;
      } else {
        console.error('Error fetching cell values:', data.error);
        return {};
      }
    } catch (error) {
      console.error('Error fetching cell values:', error);
      return {};
    }
  };

  const handleInputChange = (id: number, value: string | number) => {
    setAnswers((prevAnswers) => ({
      ...prevAnswers,
      [id]: {
        ...prevAnswers[id],
        value: value,
      },
    }));
    setIsSaved(false); // Mark as unsaved when inputs change
  };

  // Handle "Finish Question" click with immediate save
  const handleBack = async () => {
    if (hasSpreadsheetInput) {
      setIsSaving(true);
      // Get back the updated answers from handleExtractCellValues
      const latestAnswers = await handleExtractCellValues();
      setIsSaving(false);

      // Compare to last saved
      const latestAnswersString = JSON.stringify(latestAnswers);
      const lastSavedAnswersString = JSON.stringify(lastSavedAnswersRef.current);
      if (latestAnswersString !== lastSavedAnswersString) {
        // Pass the latest answers to your save function
        handleSave(latestAnswers);
      }
    } else {
      handleSave();
    }
    onBack();
  };

  const handleConfirm = () => {
    handleBack();
    setShowConfirm(false);
  }

  const handleSave = (answersToSave?: QuestionAnswer) => {
    const finalAnswers = answersToSave ?? answers;
    onSaveAnswers(finalAnswers);
    lastSavedAnswersRef.current = { ...finalAnswers };
    setIsSaved(true);
    setLastSaveTime(new Date());
    // Clear any existing timeout
    if (saveTimeoutRef.current) {
      clearTimeout(saveTimeoutRef.current);
      saveTimeoutRef.current = null;
    }
  };

  // Handle Extract Cell Values button click
  const handleExtractCellValues = async () => {
    setIsExtracting(true);
    // 1. Gather cells to fetch
    const spreadsheetCells = questionParts
      .filter((part) => part.inputType === 'spreadsheet' && part.cell)
      .map((part) => ({
        id: part.id,
        cell: part.cell,
        cellType: part.cellType,
      }));

    // 2. If nothing to fetch, just return current answers
    if (spreadsheetCells.length === 0) {
      setIsExtracting(false);
      return answers; // no update
    }

    try {
      const cellValues = await fetchCellValues(spreadsheetCells);
      const updatedAnswers = { ...answers };
      questionParts.forEach((part) => {
        if (part.inputType === 'spreadsheet' && part.cell) {
          updatedAnswers[part.id] = cellValues[part.id] || { value: '', formula: '', multipleValues: false };
        }
      });
      // 3. Update local state
      setAnswers(updatedAnswers);
      // Mark as unsaved
      setIsSaved(false);
      return updatedAnswers;
    } catch (error) {
      console.error('Error extracting cell values:', error);
      return answers; // Return old answers if error
    } finally {
      setIsExtracting(false);
    }
  };

  useEffect(() => {
    document.body.style.overscrollBehaviorX = 'none';
    return () => {
      document.body.style.overscrollBehaviorX = '';
    };
  }, []);

  return (
    <div className="relative h-screen bg-gray-100 flex flex-row">
      {/** Left Panel */}
      <div
        className={`transition-all duration-300 bg-white flex flex-col border-r border-gray-300 mr-3 mt-1 rounded-md ${
          isLeftPanelOpen ? 'w-1/4' : 'w-0 overflow-hidden'
        }`}
      >
        {isLeftPanelOpen && (
          <>
            {/* Scrollable content */}
            <div className="flex-1 p-6 overflow-auto">
              <div className="flex items-center justify-between mb-4">
                <Button onClick={
                  () => {
                    if (disableAfterCompleting) {
                      showCustomConfirm('Are you sure you want to finish this question? You will not be able to edit it again.');
                    } else {
                      handleBack();
                    }
                  }
                } bold className="mb-4" disabled={isSaving}>
                  {isSaving ? 'Saving...' : 'Finish Question'}
                </Button>
                {/* Toggle Panel Button */}
                <button
                  onClick={() => setIsLeftPanelOpen(false)}
                  className="text-gray-500 hover:text-gray-700 focus:outline-none"
                >
                  <ChevronRightIcon className="h-5 w-5" />
                </button>
              </div>

              {/* Question Instruction */}
              {questionInstruction && (
                <div className="mb-6">
                  <h2 className="text-lg font-semibold text-gray-800">Question Instruction</h2>
                  <p className="text-gray-600 text-sm">{questionInstruction}</p>
                </div>
              )}

              {/* Question Assumptions */}
              {questionAssumptions && (
                <div className="mb-6">
                  <h2 className="text-lg font-semibold text-gray-800">Question Assumptions</h2>
                  <ul className="list-disc list-inside text-gray-600 text-sm">
                    {questionAssumptions.split('. ').map((assumption, index) => (
                      <li key={index}>{assumption}</li>
                    ))}
                  </ul>
                </div>
              )}

              {/*
                If there's at least one visible spreadsheet question, we show
                the Show Formulas checkbox and the Extract Cells button here.

                Otherwise, we'll move them into the hidden-parts drawer below.
              */}
              {hasVisibleSpreadsheet && (
                <>
                  {/* Show Formulas checkbox */}
                  <div className="flex items-center mb-4">
                    <label className="flex items-center">
                      <input
                        type="checkbox"
                        checked={showFormulas}
                        onChange={(e) => setShowFormulas(e.target.checked)}
                        className="form-checkbox"
                      />
                      <span className="ml-2 text-gray-700">Show Formulas</span>
                    </label>
                  </div>

                  {/* Extract Cells button */}
                  {hasSpreadsheetInput && (
                    <Button
                      onClick={handleExtractCellValues}
                      disabled={isExtracting}
                      className="flex items-center px-4 py-2 text-sm mb-4"
                    >
                      <DownloadIcon className="h-5 w-5 mr-2" />
                      {isExtracting ? 'Extracting...' : 'Extract Cells'}
                    </Button>
                  )}
                </>
              )}

              {/* Render all NON-hidden parts (i.e., visible parts) */}
              {visibleParts.map((part, index) => {
                const answerData = answers[part.id];
                return (
                  <div key={index} className="mb-6">
                    <p className="font-semibold text-gray-700">{part.text}</p>
                    {part.inputType === 'user' ? (
                      part.answerFormat === 'number' ? (
                        <input
                          type="number"
                          value={answerData?.value || ''}
                          onChange={(e) => handleInputChange(part.id, e.target.value)}
                          className="mt-2 p-3 border border-gray-300 rounded-lg w-full focus:outline-none focus:ring-2 focus:ring-blue-500"
                        />
                      ) : (
                        <textarea
                          value={answerData?.value || ''}
                          onChange={(e) => handleInputChange(part.id, e.target.value)}
                          className="mt-2 p-3 border border-gray-300 rounded-lg w-full focus:outline-none focus:ring-2 focus:ring-blue-500"
                        />
                      )
                    ) : // Spreadsheet parts
                    part.cellType === 'single' && answerData.multipleValues === false ? (
                      <p className="mt-2 p-3 border border-gray-300 rounded-lg w-full bg-gray-100 text-gray-600">
                        {showFormulas ? answerData?.formula : answerData?.value}
                      </p>
                    ) : (part.cellType === 'row' || part.cellType === 'column') && answerData.multipleValues === true ? (
                      <p className="mt-2 p-3 border border-gray-300 rounded-lg w-full bg-gray-100 text-gray-600">
                        {showFormulas ? answerData?.formula.join(', ') : answerData?.value.join(', ')}
                      </p>
                    ) : (
                      // displayValue &&
                      // displayValue.map((row: any[], rowIndex: number) => (
                      //   <div key={rowIndex} className="flex space-x-1">
                      //     {row.map((cell, cellIndex) => (
                      //       <p
                      //         key={cellIndex}
                      //         className="p-2 border border-gray-300 rounded-lg bg-gray-100 text-gray-600"
                      //       >
                      //         {cell}
                      //       </p>
                      //     ))}
                      //   </div>
                      // ))
                      <p>Display of block cells not supported</p>
                    )}
                  </div>
                );
              })}          
              
            </div>

            {/* Fixed Footer with Save Status */}
            <div className="p-4 bg-white border-t border-gray-200 flex justify-end">
              <div className="flex flex-col items-end space-y-2">
                {!isSaved ? (
                  <>
                    <Button onClick={() => handleSave()} className="px-4 py-2 text-sm">
                      Save
                    </Button>
                    <div className="flex items-center text-gray-600 text-sm">
                      <ExclamationCircleIcon className="h-5 w-5 text-yellow-500 mr-1" />
                      <span>Unsaved changes</span>
                    </div>
                  </>
                ) : (
                  <div className="flex items-center text-green-600 text-sm">
                    <CheckCircleIcon className="h-5 w-5 text-green-500 mr-1" />
                    <span>All changes saved</span>
                  </div>
                )}
                {lastSaveTime && (
                  <div className="flex items-center text-gray-600 text-sm">
                    <span>Last saved at {lastSaveTime.toLocaleTimeString()}</span>
                  </div>
                )}
              </div>
            </div>
          </>
        )}
      </div>

      {/* If the panel is closed, we just show the toggle button and no Finish Question button */}
      {!isLeftPanelOpen && (
        <div className="absolute top-4 left-4 z-50 flex items-center space-x-2">
          <button
            onClick={() => setIsLeftPanelOpen(true)}
            className="p-2 bg-white rounded-full shadow hover:bg-gray-100 focus:outline-none"
          >
            <ChevronDownIcon className="h-5 w-5 text-gray-700 rotate-90" />
          </button>
        </div>
      )}

      {/* Right side: Embedded Google Spreadsheet */}
      <div className="flex-1 h-full relative">
        {sheetLink && (
          <iframe
            src={sheetLink}
            title="Spreadsheet"
            className="w-full h-full border-r border-gray-300"
          />
        )}
      </div>

      <ConfirmModal
        title="Finish Question"
        isOpen={showConfirm}
        message={confirmMessage}
        onConfirm={handleConfirm}
        onClose={() => setShowConfirm(false)}
      />
    </div>
  );
};

export default SpreadsheetQuestion;
