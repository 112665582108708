import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { doc, getDoc } from 'firebase/firestore';
import { db } from '../firebase'; // Import Firebase storage

import Button from '../components/Button';
import Interview from '../../types/Interview';

interface Props {}

const Instructions: React.FC<Props> = () => {
  const { interviewId = '' } = useParams<{ interviewId: string }>();
  const navigate = useNavigate();

  const [interviewData, setInterviewData] = useState<Interview | null>(null);
  const [loading, setLoading] = useState<boolean>(true);

  const handleStart = () => {
    navigate(`/interview/${interviewId}`);
  };

  useEffect(() => {
    const fetchInterviewData = async () => {
      if (interviewId) {
        try {
          const docRef = doc(db, 'interviews', interviewId);
          const docSnap = await getDoc(docRef);
          if (docSnap.exists()) {
            const data = docSnap.data() as Interview;
            setInterviewData(data);
          } else {
            console.log('No such document!');
            // Optionally, set an error state here
          }
        } catch (e) {
          console.error('Error fetching interview data:', e);
          navigate('/dashboard');
        } finally {
          setLoading(false);
        }
      } else {
        navigate('/dashboard');
      }
    };
    fetchInterviewData();
  }, [interviewId, navigate]);

  if (loading) {
    return (
      <div className="flex items-center justify-center h-screen bg-blue-50">
        <div className="text-xl text-gray-700">Loading instructions...</div>
      </div>
    );
  }

  if (!interviewData) {
    return (
      <div className="flex items-center justify-center h-screen bg-blue-50">
        <div className="text-xl text-red-500">Unable to load interview data.</div>
      </div>
    );
  }

  const {
    firstName,
    roleName,
    companyName,
    timeLimitMinutes,
    interviewInstructions,
  } = interviewData;

  return (
    <div className="flex items-center justify-center min-h-screen bg-blue-50 p-4">
      <div className="bg-white p-8 rounded-xl shadow-lg w-full max-w-lg">
        {/* Dynamic Title */}
        <h2 className="text-3xl font-extrabold text-gray-800 mb-4 text-center">
          Instructions
        </h2>

        {/* Personalized Greeting */}
        <p className="mb-4 text-lg text-gray-700 text-center">
          Hello {firstName}, you are about to complete a technical pre-screen for the {' '}
          <span className="font-bold">{roleName}</span> position at{' '}
          <span className="font-bold">{companyName}</span>.
        </p>

        {/* Time Limit Information */}
        <p className="mb-6 text-lg text-gray-700 text-center">
          You have <span className="font-bold">{timeLimitMinutes} minutes</span> to complete the
          interview.
        </p>

        {/* Default Instructions */}
        <div className="mb-6 text-gray-700">
          <h3 className="text-xl font-semibold mb-2">Instructions:</h3>
          <ul className="list-disc list-inside text-gray-600">
            <li className="mb-2">No web searches</li>
            <li className="mb-2">No use of generative AI tools</li>
            <li className="mb-2">No collaboration with others</li>
            <li className="mb-2">No use of notes or external materials</li>
          </ul>
        </div>

        {/* Additional Instructions (if any) */}
        {interviewInstructions && (
          <div className="mb-6 text-gray-700">
            <h3 className="text-xl font-semibold mb-2">Your task:</h3>
            <p>{interviewInstructions}</p>
          </div>
        )}

        {/* Start Interview Button */}
        <div className="text-center">
          <Button onClick={handleStart} bold>
            Start Interview
          </Button>
        </div>
      </div>
    </div>
  );
};

export default Instructions;
