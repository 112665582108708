import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import full_logo from '../assets/full_logo.png';
import NewLogo from '../assets/NewLogo.png';
import AntiCheating from '../assets/AntiCheating.png';
import ATSNew from '../assets/ATSNew.png';
import PerformanceReview from '../assets/PerformanceReview.png';
import ThreeStatementPic from '../assets/ThreeStatementPic.png';
import { ChartBarIcon, ChipIcon, UsersIcon, ShieldCheckIcon } from '@heroicons/react/outline';
import { FaLinkedin, FaFacebook, FaTwitter } from 'react-icons/fa'; 
import NavBar from '../components/NavBar';

const Landing = () => {
  const [activeIndex, setActiveIndex] = useState(null);

  const toggleFAQ = (index) => {
    setActiveIndex(activeIndex === index ? null : index);
  };

  const scrollToSection = (id) => {
    const element = document.getElementById(id);
    if (element) {
      element.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }
  };

  const HeroSection = () => (
    <div className="w-full bg-white pt-32 pb-32 px-6" id="hero">
      <div className="md:max-w-[1080px] w-full m-auto flex flex-col md:flex-row items-center justify-center space-y-12 md:space-y-0">
        {/* Left Text Section */}
        <div className="w-full md:w-1/2 flex flex-col items-center md:items-start text-center md:text-left space-y-6">
          <h1 className="text-6xl font-bold leading-tight text-black">
            A thousand case study interviews<br className="hidden md:block"/> with the click of a button.
          </h1>
          <p className="text-xl text-gray-700">
            AI powered case study interviews for financial services firms.
          </p>
          <a
            href="https://cal.com/williamma/finterview-demo"
            target="_blank"
            rel="noopener noreferrer"
            className="inline-block px-8 py-3 bg-[#20B486] text-white font-semibold rounded-full hover:bg-[#1a906b] transition-colors text-lg"
          >
            Demo Finterview
          </a>
        </div>
  
        {/* Right Image Section */}
        <div className="w-full md:w-1/2 flex justify-center md:justify-start">
          <img
            src={NewLogo}
            alt="NewLogo"
            className="w-full object-cover"
          />
        </div>
      </div>
    </div>
  );

  const features = [
    {
      title: "Automate your case studies",
      description: (
        <p className="text-gray-600 text-lg mt-8">
          <ChartBarIcon className="h-6 w-6 inline mr-2 text-[#20B486]" />
          Provide candidates with automated case studies: Financial Statements, Valuations, LBO Modeling, and more.
        </p>
      ),
      image: ThreeStatementPic,
      buttonLabel: "Schedule a Demo"
    },
    {
      title: "Rank your candidates",
      description: (
        <p className="text-gray-600 text-lg mt-8">
          <ChipIcon className="h-6 w-6 inline mr-2 text-[#20B486]" />
          Finterview's AI granularly and automatically ranks candidates based on performance.
        </p>
      ),
      image: PerformanceReview,
      buttonLabel: "Get Started"
    },
    {
      title: "Manage your recruiting process",
      description: (
        <p className="text-gray-600 text-lg mt-8">
          <UsersIcon className="h-6 w-6 inline mr-2 text-[#20B486]" />
          End-to-End Applicant Tracking System to manage recruiting from resume drop to offer.
        </p>
      ),
      image: ATSNew,
      buttonLabel: "Explore Features"
    },
    {
      title: "Prevent Cheating",
      description: (
        <p className="text-gray-600 text-lg mt-8">
          <ShieldCheckIcon className="h-6 w-6 inline mr-2 text-[#20B486]" />
          Ensure assessment integrity with webcam monitoring, audio recording, screen sharing, and other anti-cheating measures.
        </p>
      ),
      image: AntiCheating,
      buttonLabel: "Learn More"
    },
  ];

  const FeaturesSection = () => (
    <div id="features-0" className="w-full">
      {features.map((feature, index) => (
        <div
          key={index}
          className={`${
            index % 2 === 0 ? 'bg-white' : 'bg-gray-50'
          } w-full py-32 px-6 flex items-center`}
          id={`features-${index}`}
        >
          <div className="md:max-w-[1080px] w-full mx-auto flex flex-col md:flex-row-reverse items-center justify-center space-y-12 md:space-y-0 md:gap-8">
            {/* Image Section */}
            <div className="md:w-2/3 flex justify-center items-center">
              <div className="relative">
                <img
                  src={feature.image}
                  alt={feature.title}
                  className="w-full shadow-md rounded-[10px] border border-gray-200 transition-transform duration-500 ease-in-out"
                />
              </div>
            </div>
  
            {/* Text Section */}
            <div className="md:w-1/3 text-center md:text-left">
              <h3 className="text-5xl font-bold text-black mt-8 md:mt-0">{feature.title}</h3>
              <div className="text-gray-600 text-lg mt-8">{feature.description}</div>
              <div className="mt-8">
                <a
                  href="https://cal.com/williamma/finterview-demo"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="inline-block px-6 py-2 bg-[#20B486] text-white font-semibold text-base rounded-full hover:bg-[#1a906b] transition-transform duration-300 ease-in-out hover:scale-110"
                >
                  {feature.buttonLabel}
                </a>
              </div>
            </div>
          </div>
        </div>
      ))}
    </div>
  );

  const CTASection = () => (
    <div className="w-full bg-[#20B486] py-32 px-6" id="cta">
      <div className="md:max-w-[1080px] m-auto text-center text-white px-4 flex flex-col items-center justify-center space-y-6">
        <h2 className="text-5xl font-bold">Ready to Transform Your Finance Hiring Process?</h2>
        <p className="text-xl">Contact our sales team to find the best solution for your firm.</p>
        <div className="mt-12">
          <a
            href="https://cal.com/williamma/finterview-demo"  
            rel="noopener noreferrer"
            className="px-8 py-4 bg-white text-[#20B486] font-semibold text-lg rounded-full hover:bg-gray-100 transition-colors"
          >
            Contact us
          </a>
        </div>
      </div>
    </div>
  );

  const FAQSection = () => {
    const faqs = [
      {
        question: "What if we already have tests prepared?",
        answer: "We can integrate your existing tests, adapting them slightly for accurate automated grading.",
      },
      {
        question: "Does Finterview come with tests for me to choose from?",
        answer: "Yes, we have a suite of tests for Investment Banking, Private Equity, Consulting, Strategy, FP&A, and more.",
      },
      {
        question: "Do you have Finterview Tests for my Industry?",
        answer: "We cover IB, PE, Consulting, Strategy, FP&A, and more. If you're outside these categories, reach out—we'd love to help.",
      },
      {
        question: "Do you offer customer support?",
        answer: (
          <span>
            Yes! Contact us at{' '}
            <a href="mailto:team@finterview.me?subject=Support" className="text-[#20B486] underline">
              team@finterview.me
            </a>{' '}and we'll respond ASAP.
          </span>
        ),
      },
      {
        question: "How much does Finterview Cost?",
        answer: (
          <span>
            Please reach out at{' '}
            <a href="mailto:team@finterview.me?subject=Support" className="text-[#20B486] underline">
              team@finterview.me
            </a>{' '} or schedule a demo for a quote.
          </span>
        ),
      },
    ];

    return (
      <div className="w-full bg-white py-24 px-6" id="faq">
        <div className="md:max-w-[1080px] m-auto px-4">
          <h2 className="text-4xl font-bold text-center mb-12">Frequently Asked Questions</h2>
          <div className="max-w-2xl m-auto">
            {faqs.map((faq, index) => {
              const isActive = activeIndex === index;
              return (
                <div key={index} className="border-b border-gray-300">
                  <button
                    onClick={() => toggleFAQ(index)}
                    className="w-full text-left flex justify-between items-center py-4 text-xl font-medium text-gray-800 hover:text-[#20B486] transition-colors focus:outline-none"
                    aria-expanded={isActive}
                    aria-controls={`faq-${index}`}
                  >
                    <span>{faq.question}</span>
                    <svg
                      className={`w-6 h-6 transform transition-transform duration-300 ${isActive ? 'rotate-180' : 'rotate-0'}`}
                      fill="none"
                      stroke="currentColor"
                      viewBox="0 0 24 24"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M19 9l-7 7-7-7" />
                    </svg>
                  </button>
                  <div
                    id={`faq-${index}`}
                    className={`overflow-hidden transition-[max-height] duration-500 ease-in-out ${isActive ? 'max-h-[300px]' : 'max-h-0'}`}
                  >
                    <div className="py-4 text-gray-600 text-base">
                      <p>{faq.answer}</p>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    );
  };

  const ContactSection = () => (
    <div className="w-full bg-white py-24" id="contact">
      <div className="md:max-w-[1080px] m-auto px-4">
        <h2 className="text-4xl font-bold text-center mb-8">Get in Touch</h2>
        <p className="text-center text-gray-700 mb-12 text-lg">
          Have questions? Reach out at <a href="mailto:team@finterview.me" className="text-[#20B486] underline">team@finterview.me</a>
        </p>
      </div>
    </div>
  );

  const Footer = () => (
    <footer className="w-full bg-gray-900 text-white py-12 px-6">
      <div className="md:max-w-[1480px] m-auto">
        <div className="grid md:grid-cols-4 gap-8 text-center md:text-left">
          <div className="flex flex-col items-center md:items-start">
            <img src={NewLogo} alt="New Logo" className="h-12 mb-2" />
            <p className="text-[#20B486] font-semibold">Finterview</p>
            <p className="text-gray-400 text-sm mt-2">
              Revolutionizing finance technical interviews through AI-powered assessments.
            </p>
          </div>
          <div>
            <h4 className="font-semibold mb-4 text-white">Quick Links</h4>
            <ul className="space-y-2 text-sm">
              <li>
                <button
                  onClick={() => scrollToSection('features-0')}
                  className="text-gray-400 hover:text-white"
                >
                  Features
                </button>
              </li>
              <li>
                <button
                  onClick={() => scrollToSection('contact')}
                  className="text-gray-400 hover:text-white"
                >
                  Contact
                </button>
              </li>
            </ul>
          </div>
          <div>
            <h4 className="font-semibold mb-4 text-white">Contact</h4>
            <ul className="space-y-2 text-sm text-gray-400">
              <li>
                Email:{' '}
                <a
                  href="mailto:team@finterview.me"
                  className="hover:text-white"
                >
                  team@finterview.me
                </a>
              </li>
            </ul>
          </div>
          <div>
            <h4 className="font-semibold mb-4 text-white">Follow Us</h4>
            <div className="flex justify-center md:justify-start space-x-4">
              <a
                href="https://linkedin.com"
                target="_blank"
                rel="noopener noreferrer"
                className="text-gray-400 hover:text-white"
              >
                <FaLinkedin size={24} />
              </a>
              <a
                href="https://facebook.com"
                target="_blank"
                rel="noopener noreferrer"
                className="text-gray-400 hover:text-white"
              >
                <FaFacebook size={24} />
              </a>
              <a
                href="https://x.com"
                target="_blank"
                rel="noopener noreferrer"
                className="text-gray-400 hover:text-white"
              >
                <FaTwitter size={24} />
              </a>
            </div>
          </div>
        </div>
        <div className="border-t border-gray-800 mt-8 pt-4 text-center text-gray-400 text-xs">
          <p>&copy; {new Date().getFullYear()} Finterview. All rights reserved.</p>
        </div>
      </div>
    </footer>
  );

  return (
    <>
      <NavBar/>
      <HeroSection />
      <FeaturesSection />
      <CTASection />
      <FAQSection />
      <ContactSection />
      <Footer />
    </>
  );
};

export default Landing;
