import React, { useState, useContext, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  updateEmail,
  updatePassword,
  sendEmailVerification,
  EmailAuthProvider,
  reauthenticateWithCredential,
  deleteUser,
} from 'firebase/auth';
import { doc, updateDoc, deleteDoc } from 'firebase/firestore';
import { auth, db } from '../firebase';
import { UserContext } from '../UserContext';
import NavBar from '../components/NavBar';
import Button from '../components/Button';
import { FaEye, FaEyeSlash } from 'react-icons/fa';
import Modal from 'react-modal';
import AlertModal from '../components/AlertModal';

const Profile: React.FC = () => {
  const { user } = useContext(UserContext);
  const [currentPassword, setCurrentPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [confirmNewPassword, setConfirmNewPassword] = useState('');
  const [newEmail, setNewEmail] = useState('');
  const [emailCurrentPassword, setEmailCurrentPassword] = useState('');

  const [showEmailCurrentPassword, setShowEmailCurrentPassword] = useState(false);

  const [showCurrentPassword, setShowCurrentPassword] = useState(false);
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [isEmailVerified, setIsEmailVerified] = useState(false);
  const [alertMessage, setAlertMessage] = useState('');
  const [alertTitle, setAlertTitle] = useState('');
  const [showAlert, setShowAlert] = useState(false);
  
  const navigate = useNavigate();

  useEffect(() => {
    if (auth.currentUser) {
      setIsEmailVerified(auth.currentUser.emailVerified);
      setNewEmail(auth.currentUser.email || '');
    }
  }, []);

  const showCustomAlert = (title: string, message: string) => {
    setAlertMessage(message);
    setAlertTitle(title);
    setShowAlert(true);
  };

  const handleReauthenticate = async (password) => {
    if (!auth.currentUser?.email) return false;
    
    try {
      const credential = EmailAuthProvider.credential(
        auth.currentUser.email,
        password
      );
      await reauthenticateWithCredential(auth.currentUser, credential);
      return true;
    } catch (error) {
      console.error('Reauthentication failed:', error);
      showCustomAlert('Error', 'Current password is incorrect');
      return false;
    }
  };

  const handlePasswordChange = async (e: React.FormEvent) => {
    e.preventDefault();
    if (newPassword !== confirmNewPassword) {
      showCustomAlert('Error', 'New passwords do not match');
      return;
    }

    if (!auth.currentUser) return;

    try {
      const isReauthenticated = await handleReauthenticate(currentPassword);
      if (!isReauthenticated) return;

      await updatePassword(auth.currentUser, newPassword);
      showCustomAlert('Success', 'Password updated successfully');
      setCurrentPassword('');
      setNewPassword('');
      setConfirmNewPassword('');
    } catch (error) {
      console.error('Error updating password:', error);
      showCustomAlert('Error', 'Failed to update password');
    }
  };

  const handleEmailChange = async (e: React.FormEvent) => {
    e.preventDefault();
    if (!auth.currentUser) return;

    try {
      const isReauthenticated = await handleReauthenticate(emailCurrentPassword);
      if (!isReauthenticated) return;

      await updateEmail(auth.currentUser, newEmail);
      await sendEmailVerification(auth.currentUser);
      
      // Update email in Firestore
      const userDoc = doc(db, 'users', auth.currentUser.uid);
      await updateDoc(userDoc, {
        email: newEmail
      });

      showCustomAlert('Success', 'Email update initiated. Please check your new email for verification.');
    } catch (error) {
      console.error('Error updating email:', error);
      showCustomAlert('Error', 'Failed to update email');
    }
  };

  const handleSendVerification = async () => {
    if (!auth.currentUser) return;
    try {
      await sendEmailVerification(auth.currentUser);
      showCustomAlert('Success', 'Verification email sent. Please check your inbox.');
    } catch (error) {
      console.error('Error sending verification:', error);
      showCustomAlert('Error', 'Failed to send verification email');
    }
  };

  const handleEmailSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    handleEmailChange(e);
  };

  const handlePasswordSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    handlePasswordChange(e);
  };

  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [deletePassword, setDeletePassword] = useState('');
  const [showDeletePassword, setShowDeletePassword] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);

  const handleDeleteAccount = async () => {
    if (!auth.currentUser) return;
    
    setIsDeleting(true);
    try {
      // First reauthenticate
      const isReauthenticated = await handleReauthenticate(deletePassword);
      if (!isReauthenticated) {
        setIsDeleting(false);
        return;
      }

      // Delete user data from Firestore
      await deleteDoc(doc(db, 'users', auth.currentUser.uid));

      // If user is a recruiter, clean up their interviews and results
      if (user?.userType === 'recruiter') {
        // TODO: You might want to add additional cleanup here for recruiter data
        // For example, deleting all interviews and results associated with this recruiter
      }

      // Delete the user's authentication account
      await deleteUser(auth.currentUser);
      
      // Navigate to home page
      navigate('/');
    } catch (error) {
      console.error('Error deleting account:', error);
      showCustomAlert('Error', 'Failed to delete account. Please try again later.');
    } finally {
      setIsDeleting(false);
      setShowDeleteModal(false);
      setDeletePassword('');
    }
  };

  // Add this to your return JSX, after the password change form
  const deleteAccountSection = (
    <div className="bg-white rounded-lg shadow-md p-6 mt-6">
      <h2 className="text-xl font-bold mb-4">Delete Account</h2>
      <p className="text-red-600 mb-4">
        Warning: This action cannot be undone. All your data will be permanently deleted.
      </p>
      <Button 
        onClick={() => setShowDeleteModal(true)} 
        color="red"
        className="w-full"
      >
        Delete Account
      </Button>

      <Modal
        isOpen={showDeleteModal}
        onRequestClose={() => {
          setShowDeleteModal(false);
          setDeletePassword('');
        }}
        className="Modal"
        overlayClassName="Overlay"
        ariaHideApp={false}
      >
        <div className="p-6 bg-white rounded-lg max-w-md mx-auto">
          <h2 className="text-2xl font-bold mb-4">Confirm Account Deletion</h2>
          <p className="mb-4">
            Are you sure you want to delete your account? This action cannot be undone.
          </p>
          <div className="mb-4 relative">
            <label className="block text-gray-700 font-bold mb-2">
              Enter your password to confirm
            </label>
            <div className="relative">
              <input
                type={showDeletePassword ? 'text' : 'password'}
                className="w-full p-3 border rounded-md focus:outline-none focus:ring-2 focus:ring-blue-300"
                value={deletePassword}
                onChange={(e) => setDeletePassword(e.target.value)}
                required
              />
              <div
                className="absolute inset-y-0 right-0 pr-3 flex items-center cursor-pointer"
                onClick={() => setShowDeletePassword(!showDeletePassword)}
              >
                {showDeletePassword ? <FaEyeSlash /> : <FaEye />}
              </div>
            </div>
          </div>
          <div className="flex justify-end space-x-4">
            <Button
              onClick={() => {
                setShowDeleteModal(false);
                setDeletePassword('');
              }}
              color="gray"
              disabled={isDeleting}
            >
              Cancel
            </Button>
            <Button
              onClick={handleDeleteAccount}
              color="red"
              disabled={!deletePassword || isDeleting}
            >
              {isDeleting ? 'Deleting...' : 'Delete Account'}
            </Button>
          </div>
        </div>
      </Modal>
    </div>
  );

  return (
    <div>
      <NavBar />
      <div className="pt-16 min-h-screen bg-blue-50">
        <div className="max-w-4xl mx-auto p-6">
          <h1 className="text-3xl font-bold mb-8">Profile Settings</h1>
          
          {/* User Info Section */}
          <div className="bg-white rounded-lg shadow-md p-6 mb-6">
            <h2 className="text-xl font-bold mb-4">User Information</h2>
            <p className="mb-2"><strong>Name:</strong> {user?.name}</p>
            <p className="mb-2"><strong>Email:</strong> {auth.currentUser?.email}</p>
            <p className="mb-2">
              <strong>Account Type:</strong> {user?.userType === 'recruiter' ? 'Recruiter' : 'Job Candidate'}
            </p>
            <p className="mb-2">
              <strong>Email Status:</strong>{' '}
              {isEmailVerified ? (
                <span className="text-green-600">Verified</span>
              ) : (
                <span className="text-red-600">
                  Not Verified{' '}
                  <button
                    onClick={handleSendVerification}
                    className="text-blue-600 underline"
                  >
                    Send Verification Email
                  </button>
                </span>
              )}
            </p>
          </div>

          {/* Change Email Section */}
          <form onSubmit={handleEmailSubmit} className="bg-white rounded-lg shadow-md p-6 mb-6">
            <h2 className="text-xl font-bold mb-4">Change Email</h2>
            <div className="mb-4">
              <label className="block text-gray-700 font-bold mb-2">New Email</label>
              <input
                type="email"
                className="w-full p-3 border rounded-md focus:outline-none focus:ring-2 focus:ring-blue-300"
                value={newEmail}
                onChange={(e) => setNewEmail(e.target.value)}
                required
              />
            </div>
            <div className="mb-4 relative">
              <label className="block text-gray-700 font-bold mb-2">Current Password</label>
              <div className="relative">
                <input
                  type={showEmailCurrentPassword ? 'text' : 'password'}
                  className="w-full p-3 border rounded-md focus:outline-none focus:ring-2 focus:ring-blue-300"
                  value={emailCurrentPassword}
                  onChange={(e) => setEmailCurrentPassword(e.target.value)}
                  required
                />
                <div
                  className="absolute inset-y-0 right-0 pr-3 flex items-center cursor-pointer"
                  onClick={() => setShowEmailCurrentPassword(!showEmailCurrentPassword)}
                >
                  {showEmailCurrentPassword ? <FaEyeSlash /> : <FaEye />}
                </div>
              </div>
            </div>
            <Button onClick={handleEmailSubmit} className="w-full">Update Email</Button>
          </form>

          {/* Change Password Section */}
          <form onSubmit={handlePasswordSubmit} className="bg-white rounded-lg shadow-md p-6">
            <h2 className="text-xl font-bold mb-4">Change Password</h2>
            <div className="mb-4 relative">
              <label className="block text-gray-700 font-bold mb-2">Current Password</label>
              <div className="relative">
                <input
                  type={showCurrentPassword ? 'text' : 'password'}
                  className="w-full p-3 border rounded-md focus:outline-none focus:ring-2 focus:ring-blue-300"
                  value={currentPassword}
                  onChange={(e) => setCurrentPassword(e.target.value)}
                  required
                />
                <div
                  className="absolute inset-y-0 right-0 pr-3 flex items-center cursor-pointer"
                  onClick={() => setShowCurrentPassword(!showCurrentPassword)}
                >
                  {showCurrentPassword ? <FaEyeSlash /> : <FaEye />}
                </div>
              </div>
            </div>
            <div className="mb-4 relative">
              <label className="block text-gray-700 font-bold mb-2">New Password</label>
              <div className="relative">
                <input
                  type={showNewPassword ? 'text' : 'password'}
                  className="w-full p-3 border rounded-md focus:outline-none focus:ring-2 focus:ring-blue-300"
                  value={newPassword}
                  onChange={(e) => setNewPassword(e.target.value)}
                  required
                />
                <div
                  className="absolute inset-y-0 right-0 pr-3 flex items-center cursor-pointer"
                  onClick={() => setShowNewPassword(!showNewPassword)}
                >
                  {showNewPassword ? <FaEyeSlash /> : <FaEye />}
                </div>
              </div>
            </div>
            <div className="mb-6 relative">
              <label className="block text-gray-700 font-bold mb-2">Confirm New Password</label>
              <div className="relative">
                <input
                  type={showConfirmPassword ? 'text' : 'password'}
                  className="w-full p-3 border rounded-md focus:outline-none focus:ring-2 focus:ring-blue-300"
                  value={confirmNewPassword}
                  onChange={(e) => setConfirmNewPassword(e.target.value)}
                  required
                />
                <div
                  className="absolute inset-y-0 right-0 pr-3 flex items-center cursor-pointer"
                  onClick={() => setShowConfirmPassword(!showConfirmPassword)}
                >
                  {showConfirmPassword ? <FaEyeSlash /> : <FaEye />}
                </div>
              </div>
            </div>
            <Button onClick={handlePasswordSubmit} className="w-full">Update Password</Button>
          </form>

          {/* Add the Delete Account Section */}
          {deleteAccountSection}

          {/* Add Alert Modal */}
          <AlertModal
            isOpen={showAlert}
            message={alertMessage}
            title={alertTitle}
            onClose={() => setShowAlert(false)}
          />
        </div>
      </div>
    </div>
  );
};

export default Profile;
