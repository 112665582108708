import Question from "../../types/Question";

const DCF: Question[] = [
  {
    questionNumber: 1,
    questionType: 'Spreadsheet',
    spreadsheetLink:
      "https://docs.google.com/spreadsheets/d/1DdzALHy1sHuZGUNbSD3xu3_HEDB8iOQ_vlykNdX7ySs/edit",
    questionInstruction:
      "Welcome to your DCF valuation test. Please complete the highlighted yellow cells in the spreadsheet using the assumptions to the right. Fill in EBITDA, EBIT, NOPAT, Unlevered Free Cash Flow, Present Value of UFCF, pre-tax cost of debt, after-tax cost of debt, cost of equity, total capitalization, WACC, total enterprise value, equity value, and implied share price. The goal is to derive a final implied share price under a Perpetuity Growth Approach. All monetary values are in millions unless otherwise stated.",
    questionAssumptions:
      "The current date is 12/31/2021. In 2021, EBITDA was $50mm and it is projected to grow at 10% per year. Assume straight-line depreciation of an initial $50mm of PP&E over 10 years. Interest expense is $2mm/year. Corporate tax rate is 20%. Annual capex is $30mm/year. Annual change in net working capital is $4mm/year. 2021 total debt was $100mm. 2021 cash & equivalents = $90mm. 2021 equity value was $300mm. Risk-free rate = 3.5%. Beta = 1.3. Equity risk premium = 5.5%. Long-term perpetuity growth rate = 3%. Diluted shares outstanding = 30mm.",
    questionParts: [
      // 5-year projections
      {
        id: 1,
        text: "EBITDA (2022E–2026E)",
        answerFormat: 'number',
        inputType: 'spreadsheet',
        cell: 'H4:L4',
        cellType: 'row',
        hideQuestionPart: true,
      },
      {
        id: 2,
        text: "EBIT (2022E–2026E)",
        answerFormat: 'number',
        inputType: 'spreadsheet',
        cell: 'H6:L6',
        cellType: 'row',
        hideQuestionPart: true,
      },
      {
        id: 3,
        text: "NOPAT (2022E–2026E)",
        answerFormat: 'number',
        inputType: 'spreadsheet',
        cell: 'H8:L8',
        cellType: 'row',
        hideQuestionPart: true,
      },
      {
        id: 4,
        text: "Unlevered Free Cash Flow (2022E–2026E)",
        answerFormat: 'number',
        inputType: 'spreadsheet',
        cell: 'H12:L12',
        cellType: 'row',
        hideQuestionPart: true,
      },
      {
        id: 5,
        text: "Present Value of UFCF (2022E–2026E)",
        answerFormat: 'number',
        inputType: 'spreadsheet',
        cell: 'H14:L14',
        cellType: 'row',
        hideQuestionPart: true,
      },

      // WACC calculation
      {
        id: 6,
        text: "Pre-Tax Cost of Debt",
        answerFormat: 'number',
        inputType: 'spreadsheet',
        cell: 'F21',
        cellType: 'single',
        hideQuestionPart: true,
      },
      {
        id: 7,
        text: "After-Tax Cost of Debt",
        answerFormat: 'number',
        inputType: 'spreadsheet',
        cell: 'F23',
        cellType: 'single',
        hideQuestionPart: true,
      },
      {
        id: 8,
        text: "Cost of Equity",
        answerFormat: 'number',
        inputType: 'spreadsheet',
        cell: 'F29',
        cellType: 'single',
        hideQuestionPart: true,
      },
      {
        id: 9,
        text: "Capitalization",
        answerFormat: 'number',
        inputType: 'spreadsheet',
        cell: 'F34',
        cellType: 'single',
        hideQuestionPart: true,
      },
      {
        id: 10,
        text: "WACC",
        answerFormat: 'number',
        inputType: 'spreadsheet',
        cell: 'F36',
        cellType: 'single',
        hideQuestionPart: true,
      },

      // Valuation outputs
      {
        id: 11,
        text: "Present Value of UFCF",
        answerFormat: 'number',
        inputType: 'spreadsheet',
        cell: 'L19',
        cellType: 'single',
        hideQuestionPart: true,
      },
      {
        id: 12,
        text: "Present Value of Terminal Value",
        answerFormat: 'number',
        inputType: 'spreadsheet',
        cell: 'L23',
        cellType: 'single',
        hideQuestionPart: true,
      },
      {
        id: 13,
        text: "Total Enterprise Value (TEV)",
        answerFormat: 'number',
        inputType: 'spreadsheet',
        cell: 'L24',
        cellType: 'single',
        hideQuestionPart: true,
      },
      {
        id: 14,
        text: "Equity Value",
        answerFormat: 'number',
        inputType: 'spreadsheet',
        cell: 'L27',
        cellType: 'single',
        hideQuestionPart: true,
      },
      {
        id: 15,
        text: "Implied Share Price",
        answerFormat: 'number',
        inputType: 'spreadsheet',
        cell: 'L31',
        cellType: 'single',
        hideQuestionPart: true,
      },
    ],
    requiresPreviousQuestionCompleted: false,
    questionTitle: "DCF Valuation Case",
  },
];

export default DCF;
