import React from 'react';
import Button from './Button';

interface AlertModalProps {
  isOpen: boolean;
  title?: string;
  message: string;
  onClose: () => void;
}

const AlertModal: React.FC<AlertModalProps> = ({ isOpen, title, message, onClose }) => {
  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
      <div className="bg-white p-6 rounded-md shadow-lg max-w-md w-full mx-4">
        <h2 className="text-xl font-bold mb-4">{title || 'Alert'}</h2>
        <p className="mb-6 text-gray-700">{message}</p>
        <div className="flex justify-end">
          <Button onClick={onClose} bold>OK</Button>
        </div>
      </div>
    </div>
  );
};

export default AlertModal;
